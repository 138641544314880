.selectedArchive {
    padding: 0 150px;

    .content {
        margin: 150px 60px;

        .entitle {
            margin: 50px auto;
            text-align: center;
            font-family: $font1;

            h1 {
                margin: 50px auto 20px;
            }
        }

        .archive {
            display: grid;
            grid-template-columns: 35% 65%;
            align-items: center;
            gap: 40px;

            .descriptif {
                width: 60%;
                margin: 0 auto;
            }
            
            .files {
                display: grid;
                justify-items: center;
                gap: 10px;

                .file {
                    width: 100%;
                    min-height: 200px;
                    background-size: cover;
                    border-radius: 15px;
                    cursor: pointer;
                    transition: 0.3s ease-in;

                    &:first-child {
                        min-height: 295px;
                        border-radius: 25px;
                        margin-top: 20px;
                    }
                    
                    &:hover {   
                        transform: scale(1.05);
                    }
                    
                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                   
                }
            }

            .info {

                .animators,  .lieu {
                    margin: 20px auto;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;

                    h5 {
                        font-family: $font1;
                        font-weight: 700;
                        font-style: normal;
                    }

                    img {
                        max-width: 200px;
                        border-radius: 15px;
                    }

                    .animator, .box {
                        padding: 10px;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        align-items: center;
                    }

                    
                }

            
            }
        }
    }
}

@media screen and (min-width: 1201px) and (max-width: 1650px) {

    .selectedArchive {

    .content {


        .archive {

            .files {


                .file {
                    min-height: 80px;

                    &:first-child {
                        min-height: 200px;
                    }
                   
                }
            }

            .info {

                .animators,  .lieu {


                    img {

                    }

                    .animator, .box {

                    }

                    
                }

            
            }
        }
    }
  }


}
@media screen and (min-width: 300px) and (max-width: 800px) {

    .selectedArchive {
        padding: 20px;

    .content {
        margin: 0;
        padding: 150px 20px;

        .archive {
            display: flex !important;
            flex-direction: column-reverse;

            .descriptif {
                width: 100%;
                margin: 35px auto;
            }

            .files {
                width: 100%;

                .file {
                    min-height: 80px;

                    &:first-child {
                        min-height: 200px;
                    }
                   
                }
            }

            .info {

                .animators,  .lieu {


                    img {

                    }

                    .animator, .box {

                    }

                    
                }

            
            }
        }
    }
  }


}