.eventModal {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 856%;
    left: 0;
    z-index: 6;

    .overlay {
        background: #000000b5;
        backdrop-filter: blur(5px);
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .modalBox {
        background: #efefef;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        position: absolute;
        min-width: 700px;
        max-width: 900px;
        max-height: 80vh;
        border-radius: 25px;
        padding: 20px;
        overflow-y: scroll;

        
        &::-webkit-scrollbar {
            width: 5px;
            height: 10px;
          
        } 
        
        &::-webkit-scrollbar-thumb {
            background: #222;
            border-radius: 25px;
         }
        

        .close {
            float: right;
            font-size: 24px;
            cursor: pointer;
        }

        .eventElement {
            padding: 40px 20px;
            border-radius: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            transition: 0.3s ease-in;
            cursor: pointer;

           

                .event {
                    display: flex;
                    gap: 70px;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    
                    
                .info {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    width: 100%;

                    .entitle {
                        padding: 0 20px;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        text-align: center;
                        padding-bottom: 20px;
                        transition: 0.3s ease-in;
                        
                        h5 {
                            grid-column: 1/3;
                            margin: 0;
                            font-weight: 600;
                            font-style: normal;
                            text-align: center
                        }
                        
                        .date {
                            font-size: 25px;
                        }
                        
                        .categories {
                            margin: 20px 0;
                            display: flex;
                            gap: 10px;
                            justify-content: center;

                            .category {
                            border: 2px solid #444444;
                            padding: 5px 10px;
                            border-radius: 25px;
                            font-family: "Inter";
                            font-size: 15px;

                            }
                        }

                            
                        strong {
                            font-family: $font1;
                            grid-column: 1/3;
                        }
                    }
    
                    .descriptif {
                        padding: 0 40px;

                        p {
                        text-align: left !important;
                        color: $grey;
                        }
                    }

                    .cardContent {
                        display: flex;
                        flex-direction: column;
                        padding: 0 40px;

                        .animators {
                        display: grid;
                        grid-template-columns: 40% 40%;
                        justify-content: center;
                        gap: 20px;
                        margin: 10px 0;
                        grid-column: 1;
                        border: 2px solid #fff;
                        background: linear-gradient(45deg, $grey, $black);
                        padding: 20px;
                        border-radius: 25px;

                        h5 {
                            grid-column: 1/3;
                            font-weight: 600;
                            font-style: normal;
                            text-align: center;
                            color: $white;
                        }

                        .animator {
                            padding: 10px;
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            align-items: center;
                            text-align: center;

                            span {
                                text-align: center;
                                font-family: $font3;
                                font-weight: 600;
                                color: $white;
                            }
                    
    
                            img {
                                max-width: 100px;
                                max-height: 80px;
                                border-radius: 5px;
                            }
                        }

                }
                
                .lieu {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 20px;
                    margin: 20px 0;
                    grid-column: 1;
                    border: 2px solid #fff;
                    background: linear-gradient(45deg, $black, $grey);
                    padding: 20px;
                    border-radius: 25px;

                    h5 {
                        grid-column: 1/3;
                        font-weight: 600;
                        font-style: normal;
                        text-align: center;
                        color: $white;
                    }
                    
                    .box {
                        padding: 10px;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        align-items: center;
                        text-align: center;

                        a {
                            text-align: center;
                            font-family: $font3;
                            font-weight: 600;
                            color: $white;
                        }

                        img {
                            max-width: 100px;
                            max-height: 80px;
                            border-radius: 5px;
                        }
                    }
                }

                .visuals {
                    padding: 20px;
                    display: flex;
                    gap: 10px;

                    img {
                        max-width: 150px;
                        max-height: 80px;
                        border-radius: 10px;
                        border: 3px solid $orange;
                    }
                }
            }
        }
     }
        
    }

    .editEventBtn {
        padding: 15px 20px;
        background: $orange;
        color: $white;
        font-weight: 600;
        font-family: $font1;
        border: none;
        border-radius: 25px;
        transition: 0.5s ease-in;
        width: 35%;
        margin: 0 auto;
        cursor: pointer;


        &:hover {
            background: $white;
            box-shadow: 0px 0px 20px 0px $orange;
            color: $orange;
        }
    }

    
        


    .addEvents {
        margin: 0;
        padding: 20px;
    
        .back {
            background: $black;
            width: 150px;
            padding: 15px;
            font-family: $font1;
            color: $white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: 0.3s ease-in;
            text-align: center;
            display: flex;
            gap: 10px;
            justify-content: center;
    
            a {
                color: $white;
            }
    
    
            &:hover {
                background: #636363;
            }
        }
    
        form {
            display: flex;
            flex-direction: column;
            margin: 40px auto;
            width: 90%;
            gap: 20px;
            font-family: $font1;
    
            .validationText {
                text-align: center !important;
                color: rgb(255, 101, 101);
                font-weight: 600;
            }
    
            .fichiers {
                display: flex;
                gap: 10px;
    
                .file {
                    width: 120px;
                    height: 120px;
                    border-radius: 5px;
                    display: flex;
                    position: relative;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    transition: 0.3s ease-in;
                    border: 2px solid #222;
    
                    a {
                        svg {
                            font-size: 80px;
                        }
                    }
                        
                        img {
                            box-sizing: border-box;
                            max-width: 100%;
                            max-height: 100%;
                            object-fit: cover;
                            z-index: 5;
                            transition: 0.3s ease-in;
                            position: absolute;
                            z-index: 6;
                            
                        }
    
                    .bkgd {
                        width: 120px;
                        height: 120px;
                        position: absolute;
                        background-size: contain;
                        filter: brightness(0.5) blur(2px);
                    }
    
                }

                .remove {
                    position: absolute;
                    z-index: 6;
                    height: 17px;
                    margin-top: -115px;
                    margin-left: 112px;
                    
                    svg {
                        font-size: 20px;
                        transition: 0.3s ease-in;
                        opacity: 0;
                        color: #26262f;
                    }
                }

                &:hover {

                    img {
                        filter: brightness(0.5) blur(1px);
                    }

                    .remove {
                        
                        svg {
                            opacity: 1;
                        }
                    }
                }
            }
    
            .addFileArea {
    
                .progression {
                    display: flex;
                    gap: 10px;
                    align-items: center;
    
    
                    progress {
                        width: 90%;
                    }
                }
    
                .add_file_box {
                    padding: 20px;
                    position: relative;
                    background: linear-gradient(45deg, $black, $grey);
                    border-radius: 5px;
                    color: #fff;
                    margin: 20px 0;
                    cursor: pointer;
                    transition: 0.3s ease-in;
    
    
                    &:hover {
                        box-shadow: 0px 0px 14px 1px $black;
                    }
    
    
                    .upload {
                        display: grid;
                        grid-template-columns: 10% 90%;
                        justify-items: center;
                        align-items: center;
    
                        svg {
                            font-size: 40px;
                        }
                    }
    
    
                    input {
                        position: absolute;
                        top: 0;
                        height: 100%;
                        left: -10px;
                        opacity: 0;
                    }
                }
    
            }
    
            .addInfo {
    
                display: grid;
                grid-template-columns: 50% 50%;
    
                .info {
                    grid-column: 2;
                    grid-row: 1/4;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    align-items: center;
                    justify-content: center;
                }
    
                #picture_row {
                    grid-row: 1/4;
                    text-align: center;
                }
    
                .img_row {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    margin: 20px;
                    align-items: center;
                    
                    .add_img_box {
                        position: relative;
                        width: 70px;
                        height: 70px;
                        border: 5px solid #273647;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        transition: 0.3s ease-in;
    
                            svg {
                                position: relative;
                                font-size: 45px;
                                color: #273647;
                                z-index: 3;
                                transition: 0.3s ease-in;
                            }
    
                            input {
                                position: absolute;
                                opacity: 0;
                                z-index: 6;
                                top: 0;
                                height: 70px;
                                width: 70px;
                                cursor: pointer;
                                padding: 0;
                            }
    
                            &:hover {
                                background: #273647;
                                border: 5px solid #fff;
                                
                                
                                svg {
                                    color: #fff;
                                }
                            }
                }
    
                .thumbnails {
                    display: flex;
                    gap: 10px;
    
                    
                    .img_box {
                        width: 120px;
                        height: 120px;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        transition: 0.3s ease-in;
                        background-color: #222;
    
                    .bkgd {
                        background-image: url(https://firebasestorage.googleapis.com/v0/b/wise-e496a.appspot.com/o/files%2F7ac1ee6b-3ba2-4bf9-9798-5baad08a4792.jpg?alt=media&token=3741e369-1d29-4e51-b2da-a317efc1bd10);
                        width: 120px;
                        height: 120px;
                        position: absolute;
                        background-size: contain;
                        filter: brightness(0.5) blur(2px);
                    }
    
                    .actualImg {
                        box-sizing: border-box;
                        max-width: 100%;
                        max-height: 100%;
                        object-fit: cover;
                        z-index: 5;
                        transition: 0.3s ease-in;
                    }
                    
                    .remove {
                        position: absolute;
                        z-index: 6;
                        height: 17px;
                        margin-top: -115px;
                        margin-left: 112px;
                        
                        svg {
                            font-size: 20px;
                            transition: 0.3s ease-in;
                            opacity: 0;
                            color: #26262f;
                            background: #fff;
                            border-radius: 25px;
                        }
                    }
                    
                    &:hover {
                        
                        
                        .actualImg {
                            filter: brightness(0.5) blur(1px);
                            
                        }
                        
                        .remove {
                            
                            svg {
                                opacity: 1;
                            }
                        }
                        
                        
                    }
                }
              }
    
            }
        }
           
            input {
                height: 35px;
                border: none;
                border-radius: 25px;
                padding: 0 10px;
                color: $orange;
                font-weight: 700;
                width: 100%;
               
                &::placeholder {
                    font-family: $font1;
                    font-weight: 400;
                }
            }
    
    
            .pickers {
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-bottom: 40px;
    
                > div {
                    display: grid;
                    grid-template-columns: 30% 70%;
                    align-items: center;
                    gap: 10px;
                }
            }
    
            button {
                padding: 15px 20px;
                background: $orange;
                color: $white;
                font-weight: 600;
                font-family: $font1;
                border: none;
                border-radius: 25px;
                transition: 0.5s ease-in;
                margin: 0 auto;
                cursor: pointer;
        
        
                &:hover {
                    background: $white;
                    box-shadow: 0px 0px 20px 0px $orange;
                    color: $orange;
                }
            }
    
            .react-datepicker__navigation {
                align-items: center;
                background: none !important;
                display: flex;
                justify-content: center;
                text-align: center;
                cursor: pointer;
                position: absolute;
                top: 2px;
                padding: 0 !important;
                border: none;
                z-index: 1;
                height: 32px;
                width: 32px !important;
                text-indent: -999em;
                overflow: hidden;
            }
    
            textarea {
                font-family: $font1;
                border: none;
                border-radius: 25px;
                padding: 5px 20px;
                color: $orange;
                font-weight: 600;
    
                &::placeholder {
                    font-family: $font1;
                }
            }
        }
    }
    
    }
}


@media screen and (min-width: 300px) and (max-width: 800px) {

    .eventModal {

        .modalBox {
            min-width: 77vw;
            max-width: 87vw;

            .eventElement {
                padding: 0px 20px;

                .event {

                    .info {

                        .entitle {

                            .categories {
                                flex-wrap: wrap;
                            }

                        }

                        .descriptif {
                            padding: 0;
                        }


                        .cardContent {
                            padding: 0;


                            .animators {
                                display: flex;
                                flex-wrap: wrap;
                            }
                        }
                    }
                }
            }
        }
    }
}