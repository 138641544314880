.modal {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 856%;

    


    .overlay {
        background: #000000b5;
        backdrop-filter: blur(5px);
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .modalBox {
        background: $bkgd;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        position: absolute;
        max-width: 900px;
        border-radius: 25px;
        padding: 20px;
        

        img {
            max-width: 800px;
        }

    }
}

