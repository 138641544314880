.footer {
    padding: 100px;
    background: #222;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    .logo_action-rse {
        width: 215px;
        filter: grayscale(1) brightness(5);
    }

    .financeurs {
        display: flex;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            margin: 0 auto;
            display: block;
            border-radius: 10px;
        }
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {


}


@media screen and (min-width: 300px) and (max-width: 800px) {
        .footer {
            padding: 50px 20px;
            text-align: center;
           

            .financeurs {
                max-width: 80%;
                img {
                    max-width: 200px;
                    width: auto;
                }
            }
        }

}