@keyframes slideUp {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
    
}

@keyframes zoom {
    0% {
        font-size: 22px;
    }

    50% {
        transform: rotate(-15deg);
        font-size: 30px;
    }
    100% {
        font-size: 22px;
        transform: none;
    }
    
}

@keyframes shake {
    0% {
        transform: translateX(10px);
    }
    25% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(5px);
    }
    75% {
        transform: translateX(-5px);
    }
    100% {
        transform: translateX(0);
    }
    
}


.addEvents {
    margin: 150px 0;
    padding: 80px 150px;


    .back {
        width: 150px;
        padding: 15px;
        font-family: $font1;
        color: $white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s ease-in;
        text-align: center;
        display: flex;
        gap: 10px;
        justify-content: center;

        a {
            color: $white;
        }


        &:hover {
            background: #636363;
        }
    }

   
}

@media screen and (min-width: 400px) and (max-width: 1600px) {

    .addEvents {


        form {
            width: 55%;

            .addFileArea {

                .add_file_box {

                    .upload {
                        
                    }
                }
            }
        }
    }

}