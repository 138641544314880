.loginPage {
    padding: 20px 150px 150px;
    margin-top: 150px;

    .loginChoice {
        display: flex;
        gap: 20px;
        font-family: $font2;
        margin: 0 auto;
        width: fit-content;

        span {
            font-size: 18px;
         

            &:nth-child(1), &:nth-child(3) {
                cursor: pointer;
                width: 80px;
                transition: 0.3s ease-in;


                &:hover {
                    color: rgb(69, 90, 255);
                }
            }
        }
    }



    form {
        display: flex;
        flex-direction: column;
        margin: 40px auto;
        width: 40%;
        gap: 20px;
        font-family: $font1;

        .validationText {
            text-align: center !important;
            color: rgb(255, 101, 101);
            font-weight: 600;
        }

        input[type="checkbox" i]  {
            height: 13px;
            width: 13px;
        }


        input {
            height: 35px;
            border: none;
            border-radius: 25px;
            padding: 0 10px;
            color: rgb(69, 90, 255);
            font-weight: 700;
           
            &::placeholder {
                font-family: $font1;
            }
        }

        .row {
            display: grid;
            grid-template-columns: 95% 5%;
            align-items: center;
            background: $white;
            border-radius: 25px;

            input {
                border-radius: 25px 0 0 25px;
                border-right: 1px solid #dddd;
            }

            .showPassword {
                display: flex;
                justify-content: center;
                cursor: pointer;

                svg {
                    color: #9a9a9add;
                }
            }



        }

        .storeMail, .stayLoggedIn {
            display: flex;
            align-items: center;
            gap: 10px;


            input {
                cursor: pointer;
            }
        }

        .resetPwdButton {
            color: rgb(69, 90, 255);
            font-weight: 600;
            cursor: pointer;
        }

        button {
            padding: 15px 20px;
            background: rgb(69, 90, 255);
            color: $white;
            font-weight: 600;
            font-family: $font1;
            border: none;
            border-radius: 25px;
            transition: 0.5s ease-in;
            width: 30%;
            margin: 0 auto;
            cursor: pointer;


            &:hover {
                background: $white;
                box-shadow: 0px 0px 50px 0px rgb(69, 90, 255);
                color: rgb(69, 90, 255);
            }
        }
    }
}