.submitStatus {
    margin: 115px 0;
    padding: 20px;
    height: 80px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    transform: translateY(100px);
    animation: slideUp 0.5s linear;
    animation-fill-mode: forwards;
    opacity: 0;
    width: 55%;
    margin: 20px auto;



    p {
        font-weight: 600;
    }


    svg {
        font-size: 22px;
        animation-fill-mode: forwards;
    }
}

.submitStatus.error {
    border: 2px solid #ff7272;

    p {
        color: #ff7272;
    }

    svg {
        font-size: 22px;
        color: #ff7272;
        animation: slideUp 0.7s linear, shake 0.2s 0.7s ease-in-out;
        animation-fill-mode: forwards;
    }
}

.submitStatus.success_edit, .submitStatus.success_add {
    border: 2px solid $orange;

    p {
        color: $orange;
    }

    svg {
        color: $orange;
        animation: slideUp 0.7s linear, zoom 0.5s 0.7s linear;
    }
}

form {
    display: flex;
    flex-direction: column;
    margin: 40px auto;
    width: 40%;
    gap: 20px;
    font-family: $font1;

    .validationText {
        text-align: center !important;
        color: rgb(255, 101, 101);
        font-weight: 600;
    }


    .addFileArea {

        .progression {
            display: flex;
            gap: 10px;
            align-items: center;


            progress {
                width: 90%;
            }
        }

        .add_file_box {
            padding: 20px;
            position: relative;
            background: #494949;
            border-radius: 5px;
            color: #fff;
            margin: 20px 0;
            cursor: pointer;
            transition: 0.3s ease-in;


            &:hover {
                background: #222;
            }


            .upload {
                display: grid;
                grid-template-columns: 10% 90%;
                justify-items: center;
                align-items: center;

                svg {
                    font-size: 40px;
                }
            }


            input {
                position: absolute;
                top: 0;
                height: 100%;
                left: -10px;
                opacity: 0;
                cursor: pointer;
            }
        }

    }

    .add_category {
        .categoryField {
            height: 35px;
            border: none;
            border-radius: 25px;
            background: $white;
            color: #f29b88;
            padding: 0 10px;
            font-weight: 700;
            font-family: $font1;
            width: 97%;
            display: flex;
            align-items: center;
            gap: 10px;

            .placeholder {
                font-family: $font1;
                font-weight: 400;
                font-size: 14px;
                color: #757575;
            }

            .selected {
              display: flex;
              align-items: center;
              align-content: center;
              gap: 5px;
              background: #f29b88;
              color: white;
              padding: 3px 5px;
              border-radius: 5px;

              .close {
                display: flex;
                cursor: pointer;

                svg {
                    font-weight: 600;
                }
              }
            }
           
        }

        .options {
            font-family: $font1;
            background-color: $white;
            border-radius: 15px;
            display: flex;
            flex-direction: column;

            .option {
                cursor: pointer;
                padding: 10px 20px;
                transition: 0.3s ease-in;
                display: flex;
                justify-content: space-between;

                .actions {
                    display: none;

                    .delete {
                        
                    }

                }

                

                &:first-child {
                    border-radius: 15px 15px 0 0;
                }

                &:last-child {
                    border-radius: 0 0 15px 15px;
                }


                &:hover {
                    background: rgb(228, 228, 228);

                    .actions {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        transition: 0.3s ease-in;

                        .delete {
                            transition: 0.3s ease-in;
                            
                            &:hover {
                                color: rgb(161, 11, 11);
                            }
                        }

                        .edit {
                            transition: 0.3s ease-in;
                            
                            &:hover {
                                color: $orange;
                            }
                        }
                    }
                }
            }

            .add_new_category, .edit_category {
                background: #f29b88;
                padding: 10px 20px;
                color: $white;
                width: 70%;
                margin: 10px auto;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                transition: 0.3s ease-in;

                &:hover {
                  background: $pastel_pink;
                }
             }

             .editCategoryForm {
                display: flex;
                width: 98%;
                margin: 0;

                input {
                    color: black;
                    font-family: $font1;
                    font-size: 16px;
                    font-weight: 400;
                    height: 43px;
                    border-radius: 25px 0 0 25px;
                    padding: 0 20px;
                }

                button {
                    border-radius: 0 25px 25px 0;
                    transition: 0.3s ease-in;

                    &:hover {
                        background: $pastel_pink;
                        box-shadow: none;
                        color: $white;
                      }
                }
             }

             .newCategoryForm {
                display: flex;
                width: 70%;
                margin: 10px auto;

                input {
                    background: #f5f5f5;
                    color: black;
                    font-family: $font1;
                    font-size: 16px;
                    font-weight: 400;
                    height: 43px;
                    border-radius: 25px 0 0 25px;
                    padding: 0 20px;
                }

                button {
                    border-radius: 0 25px 25px 0;
                    transition: 0.3s ease-in;

                    &:hover {
                        background: $pastel_pink;
                        box-shadow: none;
                        color: $white;
                      }
                }
             }
            }
            
        
    }

    .addInfo {

        display: grid;
        grid-template-columns: 50% 50%;

        .info {
            grid-column: 2;
            grid-row: 1/4;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            justify-content: center;
        }

        #picture_row {
            grid-row: 1/4;
            text-align: center;
        }

        .img_row {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 20px;
            align-items: center;
            
            .add_img_box {
                position: relative;
                width: 70px;
                height: 70px;
                border: 5px solid #273647;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: 0.3s ease-in;

                    svg {
                        position: relative;
                        font-size: 45px;
                        color: #273647;
                        z-index: 3;
                        transition: 0.3s ease-in;
                    }

                    input {
                        position: absolute;
                        opacity: 0;
                        z-index: 6;
                        top: 0;
                        height: 70px;
                        width: 70px;
                        cursor: pointer;
                        padding: 0;
                    }

                    &:hover {
                        background: #273647;
                        border: 5px solid #fff;
                        
                        
                        svg {
                            color: #fff;
                        }
                    }
        }

        .thumbnails {
            display: flex;
            gap: 10px;

            
            .img_box {
                width: 120px;
                height: 120px;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: 0.3s ease-in;
                background-color: #222;

            .bkgd {
                width: 120px;
                height: 120px;
                position: absolute;
                background-size: contain;
                filter: brightness(0.5) blur(2px);
            }

            .actualImg {
                box-sizing: border-box;
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
                z-index: 5;
                transition: 0.3s ease-in;
            }
            
            .remove {
                position: absolute;
                z-index: 6;
                height: 17px;
                margin-top: -115px;
                margin-left: 112px;
                
                svg {
                    font-size: 20px;
                    transition: 0.3s ease-in;
                    opacity: 0;
                    color: #26262f;
                    background: #fff;
                    border-radius: 25px;
                }
            }
            
            &:hover {
                
                
                .actualImg {
                    filter: brightness(0.5) blur(1px);
                    
                }
                
                .remove {
                    
                    svg {
                        opacity: 1;
                    }
                }
                
                
            }
        }
      }

    }
}
   
    input {
        height: 35px;
        border: none;
        border-radius: 25px;
        color: $orange;
        padding: 0 10px;
        font-weight: 700;
        width: 97%;
       
        &::placeholder {
            font-family: $font1;
            font-weight: 400;
        }
    }


    .pickers {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 40px;

        > div {
            display: grid;
            grid-template-columns: 30% 70%;
            align-items: center;
            gap: 10px;
        }
    }

    button {
        padding: 15px 20px;
        background: $orange;
        color: $white;
        font-weight: 600;
        font-family: $font1;
        border: none;
        border-radius: 25px;
        transition: 0.5s ease-in;
        width: 40%;
        margin: 0 auto;
        cursor: pointer;
        display: flex;
        align-items: center;


        &:hover {
            background: $white;
            box-shadow: 0px 0px 20px 0px $orange;
            color: $orange;
        }
    }

    .react-datepicker__navigation {
        align-items: center;
        background: none !important;
        display: flex;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        position: absolute;
        top: 2px;
        padding: 0 !important;
        border: none;
        z-index: 1;
        height: 32px;
        width: 32px !important;
        text-indent: -999em;
        overflow: hidden;
    }

    textarea {
        font-family: $font1;
        border: none;
        border-radius: 25px;
        padding: 10px 20px;
        color: $orange;
        font-weight: 600;

        &::placeholder {
            font-family: $font1;
        }
    }

    .fichiers {

        
        .file {
            position: relative;

            .remove {
                top: -5px;
                left: 135px;
                position: absolute;
                cursor: pointer;

                svg {
                    color: $orange;
                }
            }

            img {
              width: 150px;
              border-radius: 15px;
            }
        }
    }
}