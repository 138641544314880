.modal {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 9998;


    .overlay {
        background: #000000b5;
        backdrop-filter: blur(5px);
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .modalBox {
        background: $white;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        position: absolute;
        max-width: 900px;
        border-radius: 25px;
        padding: 20px;

        img {
            max-width: 800px;
            max-height: 600px;
        }

    }
}


.archives {

    .content {
        margin: 150px 60px 0;

        .btn {
            background: $black;
            width: 150px;
            padding: 10px;
            margin: 0 20px;
            font-family: $font1;
            color: $white;
            border-radius: 5px;
            cursor: pointer;
            transition: 0.3s ease-in;
            text-align: center;
         
    
            a {
                color: $white;
                display: grid;
                grid-template-columns: 10% 90%;
                padding: 0 20px;
                align-items: center;
            }
    
    
            &:hover {
                background: #636363;
            }
        }

        h1 {
            padding: 100px 0 25px;
        }

        .elements {
            display: flex;
            flex-direction: column;
            width: 85%;
            margin: 0 auto 150px;

            .list {
                display: grid;
                grid-template-columns: 33% 33% 33%;
                gap: 20px 10px;
                padding: 40px;
                border-radius: 25px;

                .archivedElement {
                    border-radius: 25px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    background: $white;
                    box-shadow: 0px 1px 11px -8px rgba(0, 0, 0, 0.7490196078);
                    height: 100%;
                    min-height: 390px;
                    transition: 0.3s ease-in;
                    cursor: pointer;

                   

                    .illustration {
                        width: 100%;
                        height: 23vh;
                        position: relative;

                        img, div {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            position: absolute;
                            border-radius: 25px 25px 0 0;
                        }

                        .no_illustration {
                            background: linear-gradient(45deg, $magenta_pastel, $orange);
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .logo {
                                width: 60%;
                                height: 60%;
                                
                                img {
                                    opacity: 0.7;
                                    filter: grayscale(1) brightness(5);
                                }
                            }
                          }
                            
                        }
                        .info {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            width: 100%;
                            height: 42%;
                            padding: 40px 0;
                            box-shadow: inset 0px 25px 53px -11px rgb(133 133 133 / 15%);
                            border-radius: 0 0 25px 25px;

                            
                                
                            

                            .entitle {
                                padding: 0 20px;
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                text-align: center;
                                padding-bottom: 20px;
                                transition: 0.3s ease-in;
                                
                                h5 {
                                    grid-column: 1/3;
                                    margin: 0;
                                    font-weight: 600;
                                    font-style: normal;
                                    text-align: center
                                }
                                
                                .categories {
                                    margin: 20px 0;
                                    display: flex;
                                    gap: 10px;
                                    justify-content: center;
                    
                                    .category {
                                    border: 2px solid #444444;
                                    padding: 5px 10px;
                                    border-radius: 25px;
                                    font-family: "Inter";
                                    font-size: 15px;
                                    align-items: center;
                                    flex-direction: column;
                    
                                    }
                                }

                                .date {
                                    font-size: 25px;
                                }
                                
                                strong {
                                    font-family: $font1;
                                    grid-column: 1/3;
                                }
                            }
            
                            .descriptif {
                                padding: 0 40px;

                                p {
                                text-align: left !important;
                                color: $grey;
                                }
                            }

                            .cardContent {
                                display: none;
                                flex-direction: column;
                                padding: 0 40px;

                                .animators {
                                display: grid;
                                grid-template-columns: 40% 40%;
                                justify-content: center;
                                gap: 20px;
                                margin: 10px 0;
                                grid-column: 1;
                                padding: 20px;
                                border-radius: 25px;
                                align-items: center;

                                h5 {
                                    grid-column: 1/3;
                                    font-weight: 600;
                                    font-style: normal;
                                    text-align: center;
                                }

                                .animator {
                                    padding: 10px;
                                    display: flex;
                                    flex-direction: column;
                                    gap: 10px;
                                    align-items: center;
                                    text-align: center;

                                    span {
                                        text-align: center;
                                        font-family: $font3;
                                        font-weight: 600;
                                    }
                            
            
                                    img {
                                        max-width: 100px;
                                        max-height: 80px;
                                        border-radius: 5px;
                                    }
                                }
        
                        }
                        
                        .lieu {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            gap: 20px;
                            margin: 20px 0;
                            grid-column: 1;
                            padding: 20px;
                            border-radius: 25px;

                            h5 {
                                grid-column: 1/3;
                                font-weight: 600;
                                font-style: normal;
                                text-align: center;
                            }
                            
                            .box {
                                padding: 10px;
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                align-items: center;
                                text-align: center;

                                a {
                                    text-align: center;
                                    font-family: $font3;
                                    font-weight: 600;
                                }

                                img {
                                    max-width: 100px;
                                    max-height: 80px;
                                    border-radius: 5px;
                                }
                            }
                        }

                        .visuals {
                            padding: 20px;
                            display: flex;
                            gap: 10px;

                            img {
                                max-width: 150px;
                                max-height: 80px;
                                border-radius: 10px;
                                border: 2px solid $black;
                            }
                        }
                    }
                
             }
                
            }

            
            }

            .row_toLeft {
                display: grid;
                grid-template-columns: 60% 40%;
                align-items: center;
                justify-items: center;

                .left {
                    display: flex;
                    flex-direction: column;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;
                }

                .imgs {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
            }

            .row_toRight {
                display: grid;
                grid-template-columns: 40% 60%;
                align-items: center;
                justify-items: center;
            }
        }
    }
}

@media screen and (min-width: 1025px) and (max-width: 1600px) {
    .archives {

        .content {

            h1 {
                padding: 50px 0;
            }

            .elements {
                width: 80%;

               
                .entitle {

                    .categories {

                        .category {
                            font-size: 11px !important;
                        }
                    }
                }
                

                    .info {

                        .animators {

                            .animator {

                                img {
                                    max-width: 150px;
                                    max-height: 85px;
                                }
                            }
                        }

                        .lieu {

                            img {
                                max-width: 100px;
                            }
                        }

                }



                img {
                    max-width: 300px;
                }
    
            }
        }
    }
}

@media screen and (min-width: 801px) and (max-width: 1024px) {


.archives {
    .content  {
        .elements {
          
                .visuals {
                    img {
                        max-width: 310px;
                        border-radius: 25px;
                        cursor: pointer;
                    }
            }
        
   }
  }   
 }
}

@media screen and (min-width: 300px) and (max-width: 800px) { 
   
    .archives {
        .content  {
            margin: 0 auto;
            padding: 150px 40px;

            .elements {
                width: 100%;

                .list {
                    display: flex;
                    flex-direction: column;
                    padding: 0px;
                    
                    .info {
                        display: flex;
                        flex-direction: column;
                    }

                    .visuals {
                        img {
                            max-width: 260px;
                            border-radius: 25px;
                            cursor: pointer;
                        }
                
                    }
            }
       }
      }   
     }
    

}