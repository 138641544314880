.account {
    margin: 150px 0;
    padding: 20px 150px;

    button {
        width: 235px;
        padding: 15px;
        font-family: $font1;
        color: $white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s ease-in;
        text-align: center;
        display: flex;
        gap: 10px;
        justify-content: center;

        a {
            color: $white;
        }


        &:hover {
            background: #636363;
        }
    }

    .tasks {
        display: flex;
        gap: 20px;
        margin: 80px auto;
        width: fit-content;
    }


    .logOut {
        margin: 50px auto;
    }
}