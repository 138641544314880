.formContainer {
   height: 0;
   background: transparent;

    * {
    
    }

    .form {
        width: 80%;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        padding: 0;
        height: 0;
        background: #ffff;
        border-radius: 25px;

        .form_part {
            padding: 0 10px;
            margin: 10px 0;
            border-left: 1px solid $light_grey;
            opacity: 0;
            height: 0;

            .questionHeader {
                font-family: $font3;
                color: $black;
                font-size: 20px !important;
                font-weight: 600;
                margin: 20px 0 0;
    
                &::after {
                    content: '';
                    display: block;
                    margin: 20px 0 10px 0;
                    width: 100%;
                    height: 1px;
                    background: $light_grey;
                }
            }
    
            .primary_label {
                font-family: $font3;
                color: $black;
                font-weight: 600;
                font-size: 15px;
            }
    
            .checkboxes {
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin: 20px;
    
                
                .chx {
                    display: grid;
                    grid-template-columns: 2% 98%;
                    align-items: center;
                    gap: 10px;
    
                    input[type="checkbox" i] {
                        height: 13px;
                        width: 13px;
                    }
    
                    label {
                        font-family: $font3;
                        font-size: 15px;
                        transform-origin: center;
                        width: fit-content;
                    }
                }
    
                .wrong {
                    svg {
                        color: red;
                    }
                }   
    
                .mid {
                    svg {
                        color: orange;
                    }
                }
                
                .good {
                    transform-origin: left;
                    
                    svg {
                        color: green;
                    }
                }
            }
        }

        .score {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 50px auto;
            width: 80%;
            gap: 10px;
            opacity: 0;

            .contactFormContainer {
                display: flex;
                flex-direction: column;
                gap: 20px;

                h5 {
                    text-align: center;
                }

                form {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  gap: 10px;
                  width: 95%;

                  input {
                    height: 30px;
                    padding: 0 10px;
                    border-radius: 25px;
                    border-top: 1px solid $magenta_pastel;
                    border-left: 1px solid $orange;
                    border-right: 1px solid $magenta_pastel;
                    border-bottom: 1px solid $orange;
                  }

                  textarea {
                    border-radius: 15px;
                    border-top: 1px solid $magenta_pastel;
                    border-left: 1px solid $orange;
                    border-right: 1px solid $magenta_pastel;
                    border-bottom: 1px solid $orange;
                    padding: 10px;
                  }

                  input, textarea {
                    font-family: $font3;
                    width: 80%;

                    &::placeholder {
                        color: $orange;
                        font-family: $font3;
                        font-weight: 400;
                    }
                  }
                }

                .form_submit_status {
                    padding: 40px;
                    border-radius: 15px;
                    
                    p {
                        text-align: center;
                    }
                }

                .form_submit_status.success {
                    border: 1px dashed $green;
                    background: #bdf7f0;
                    
                    p {
                        color: $green;
                    }
                }

                .form_submit_status.error {
                    border: 1px dashed rgb(198, 50, 50);
                    background: rgb(255 171 171);
                    
                    p {
                        color: rgb(198, 50, 50);
                    }
                }
                
            }

            span {
                font-weight: 600;
                font-family: $font3;
                text-align: center;
                font-size: 18px !important;
                white-space: pre-wrap;
            }

            button {
                margin: 20px 0;
            }
        }

        .score.red {
            color: #d34545;
        }


        .confetti {
            position: relative;


            .confetto {
                width: 10px;
                height: 10px;
                position: absolute;
                top: 0;
                left: 50%;
            }
        }

    }
}

@media screen and (min-width: 300px) and (max-width: 800px) {

    .formContainer {

        .form {
            width: 90%;

            .form_part {
                .checkboxes {
                    margin: 20px 0;

                    .chx {
                        grid-template-columns: 9% 91%;
                        justify-items: center;

                        label {
                            width: 100%;
                        }

                    }
                } 
            }

            .score {
                width: 100%;
            }
        }
    }

}