@keyframes slideInRight {
    0% {
        transform: translateX(-50px);
        opacity: 0;
        filter: blur(5px);
    }

    100% {
        transform: translateX(50px);
        opacity: 1;
        filter: none;
    }
}

@keyframes slideInLeft {
    0% {
        transform: translateX(50px);
        opacity: 0;
        filter: blur(5px);
    }

    100% {
        transform: translateX(-50px);
        opacity: 1;
        filter: none;
    }
}


@keyframes slideIn {
    0% {
        transform: translateY(50px);
        opacity: 0;
        filter: blur(5px);
    }
    100% {
        transform: translateY(-50px);
        opacity: 1;
        filter: none;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes show {
    0% {
        opacity: 0;
        display: none;
    }
    100% {
        opacity: 1;
        display: block;
    }
}

@keyframes scale {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.05);
    }
}


@keyframes bounce {
    0%, 100% {
        transform: translate(0);
    }

    50% {
        transform: translateY(-20px)
    }
}



.homePage {
    padding: 0 150px;


    section:not(#paraph-3) {
        padding: 50px 0;
    }


    .definition {

        
        .bloc {
            background: #ea836a;
            margin: 145px -255px;
            height: 45vh;
            position: relative;

            .imgBox {
                clip-path: polygon(29% 0%, 100% 0%, 75% 100%, 0% 100%);
                margin: 0 110px;
                width: 800px;

                img {
                    width: 800px;
                    filter: grayscale(1);
                }
            }

        .right {
            position: absolute;
            top: -1px;
            left: 44%;
            height: 45vh;
            display: flex;
            align-items: center;

            .whiteFrame {
                width: 45%;
                background: $white;
                width: 60%;
                margin: 20px auto;
                padding: 40px;
                border-radius: 25px;
                min-height: 27vh;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .quote {
                    font-size: 17px;
                    margin-top: 25px;
                    filter: blur(5);
                  
        
                    ul {
                        margin-left: 40px;
                    }
                }
                
        
               .title {
                font-family: $font2;
                font-size: 20px;   
                filter: blur(5),        
               }
        
                .author {
                    display: flex;
                    max-width: 300px;
                    position: absolute;
                    text-align: right;
                    font-family: $font1;
                    font-style: italic;
                    left: 68%;
                    top: 75%;
                    background: #fff;
                    flex-direction: column;
                    padding: 20px;
                    border-radius: 25px;
                    box-shadow: -40px -15px 43px -40px #80808d;
                    transition: 0.3s ease-in;
                    
                    
        
                    &:hover {
                        transform: scale(1.05);
                    }
                }
        
                img {
                    position: relative;
                    max-width: 165px;
                  }  

            }
                
        }
    } 
   }

    .caution {
        display: grid;
        grid-template-columns: 12% 88%;
        width: 60%;
        padding: 20px;
        margin: 200px auto;
        align-items: center;
        border-radius: 25px;
        justify-items: center;


        .text {
            font-family: $font1;
            display: flex;
            flex-direction: column;
        }

        svg {
            font-size: 50px;
        }
    }



    .whatIsIt {
        margin: 150px 90px 0;
        padding: 60px 0;
    }



        .logos {
            display: grid;
            grid-template-columns: 33% 33% 33%;
            gap: 50px 0;
            margin: 0 auto;
            width: 100%;
            align-items: center;
            justify-items: center;
            justify-content: center;

        .imgBox {
            display: flex;
            align-items: center;

            &:nth-child(1) {
                opacity: 0;
                transform: translateX(-50px);
                animation: slideInRight 1s ease-in-out;
                animation-fill-mode: forwards;
                filter: blur(5px);

                img {
                    width: 120px;
                  }
              
            }

            &:nth-child(2) {
                
                img {
                  width: 150px;
                }
            }

              
            &:nth-child(3) {
                opacity: 0;
                transform: translateX(50px);
                animation: slideInLeft 1s ease-in-out;
                animation-fill-mode: forwards;
                filter: blur(5px);

                img {
                  width: 120px;
                }
            }

            &:nth-child(4) {
                opacity: 0;
                transform: translateY(50px);
                animation: slideIn 1.5s 1s ease-in-out;
                animation-fill-mode: forwards;
                grid-column: 1/4;
                filter: blur(5px);
              
                img {
                  width: 310px;
                } 
                
            }
            
            img {
                display: block;
                width: 180px;       
                
             
              
            }
         }
        }

        h1 {
            font-weight: 400;
        }

 

        .team {
            display: grid;
            grid-template-columns: 50% 50%;
            margin: 160px auto;
            gap: 25px;
            width: 60%;

            h2 {
                grid-column: 1/3;
            }

            #person_1 {
                transform-origin: bottom right;
            }
            #person_2 {
                transform-origin: bottom left;
            }
            #person_3 {
                transform-origin: top right;
            }
            #person_4 {
                transform-origin: top left;
            }

            
          
            .person {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 25px;
                min-height: 250px;
                max-height: 250px;
                padding: 20px 40px;
                background: $white;
                border-radius: 25px;
                position: relative;
                cursor: pointer;
                transition: 0.3s ease-in;

                &:hover {
                    // transform: scale(1.1);
                    // display: grid;
                    // grid-template-columns: 50% 50%;
                    // align-items: center;
                    // gap: 0;
                    // justify-items: center;
                    


                    .info {
                        grid-column: 1;
                        grid-row: 1;
                        min-width: 100px;

                        a {
                            color: #497ffd;
                        }
                    }

                    .pic {
                        grid-column: 2;
                        grid-row: 1;
                    
                        .img {
                            min-height: 80px;
                            max-height: 100px;
                            min-width: 80px;
                            max-width: 100px;
                            border-radius: 50%;
                        }

                        svg {
                            font-size: 110px;
                            color: #dddddd;
                        }
                    }
                }

              

                
                .calc {
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    position: absolute;
                    border-radius: 25px;
                    transition: 0.3s ease-in;
                    background-color: transparent;
                    z-index: 4;
                }

                .pic {
                    height: 125px;
                    display: flex;
                    align-items: center;
                    z-index: 2;

                    svg {
                        font-size: 100px;
                        color: #dddddd;
                        transition: 0.2s ease-in;
                    }

                    .img {
                        min-height: 80px;
                        max-height: 100px;
                        min-width: 80px;
                        max-width: 100px;
                        border-radius: 50%;
                        background-size: cover;
                        background-position: center;
                    }
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    min-width: 315px;


                    strong, span {
                        font-family: $font1;
                    }

                    
                }
            }
        }
    

    .paraph_1 {
        margin: 50px 150px;


        ul {
            width: 53%;
            margin: auto;
        }

        a {
            width: 57%;
            margin: 20px auto;
            display: block;
        }
    }

    #tester_mes_connaissances {

        .displayQuizz {
            
            svg {
                font-size: 25px;
            }
        }

        button {
            margin: 150px auto 50px;
        }


    }

    .paraph_2  {

        .asterisk {
            text-align: center;
            font-size: 35px;
            font-weight: 900;
            background: -webkit-linear-gradient($light_orange, $magenta_pastel);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        h2 {
            margin: 5px 0 20px 0;
        }

        h3 {
            width: 55%;
            
        }

        .subTitle {
            text-align: center;
            
        }

        .pillars {
            display: grid;
            grid-template-columns: 32% 30% 31%;
            justify-content: space-around;
            gap: 50px;
            width: 95%;
            margin: 50px auto 210px;


            > div {
                display: grid;
                justify-content: center;
                justify-items: center;
                align-items: center;
                background-image: url("https://action-rse.fr/Assets/gradient.png");
                border-radius: 25px;
                padding: 40px 25px;
                height: min-content;
                gap: 10px;
                cursor: pointer;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: #ffffffab;
                    border-radius: 25px
                }

                img {
                    margin: 0 auto;
                    width: 100px;
                    z-index: 6;
                }
    
                h4 {
                 font-family: $font1;
                 margin: 10px 0;
                 z-index: 6;
                }

                .listContainer {
                    display: none;
                    z-index: 6;
                   
                    
                ul {
                    gap: 10px;
                    display: flex;
                    flex-direction: column;
                

                    li {
                        padding-left: 10px;
                        list-style: none;
                        display: grid;
                        grid-template-columns: 5% 95%;
                        gap: 10px 0;
                        line-height: 20px;
                    }
                  }
                }
            }

        }

    }
        .grid {
            display: grid;
            grid-template-columns: 50% 50%;
            gap: 20px;
            justify-content: center;
            align-items: center;
            justify-items: center;
            margin: 85px auto;
            width: 90%;

            ul {
                display: flex;
                flex-direction: column;
                gap: 20px;
                grid-row: 2 / 3;
                grid-column: 2;
            

                li {
                   list-style-type: none;
                   display: flex;
                   align-items: center;  
                   gap: 10px;
                   text-align: left;

                   svg {
                    color: $green_2;
                    font-size: 26px;
                   }
                }
            }
            
            p {
                margin: 0 0 0 50px;
                text-align: justify;
                text-justify: inter-word;
            }
            
            .subText {
                margin-left: 120px;
                margin-top: 10px;
                border-left: 1px solid #8f8f8f;
                padding-left: 10px;

                p {
                    margin: 0;
                }
            }

            img {
                max-width: 435px;
                margin: 0 auto;
                display: block;
                border-radius: 25px;
            }

            .scaleIn {
                animation: scale 0.3s linear;
                animation-fill-mode: forwards;
            }
        }

        .btn {
            margin: 0 auto;
            border-radius: 25px;
            width: 280px;
            justify-content: center;

            a {
                text-align: center;
            }
        }
    


    .paraph_3 {
        margin: -10px 0;


        ul {
            display: flex;
            flex-direction: column;
            gap: 10px;


            li {
                display: grid;
                grid-template-columns: 3% 94%;
                gap: 15px;
            }
        }

        p {
            margin: 0 60px;
        }
    }


    .grid_2 {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 20px;
        width: 90%;
        margin: 0 auto;

        h4 {
            font-size: 20px;
            margin: 5px 0;
        }

        h2 {
            grid-column: 1/3;
        }

        .needs, .practices {
            background: #fff;
            padding: 60px 40px 130px;
            border-radius: 25px;
            min-width: 145px;
            display: flex;
            flex-direction: column;
            align-content: center;
            gap: 25px;
            width: 85%;
        }
        
        .practices {
        margin: 40px 0;

        dl {
            margin: 10px 0;
            font-family: $font1;
            display: flex;
            flex-direction: column;
            gap: 10px;

            
            dt {
                font-weight: 700;
                display: flex;
                cursor: pointer;

                .icon {
                    display: flex;
                    align-items: center;
                }
            }
            
            dd {
                font-style: italic;
                padding: 0 25px;
                color: $grey;
                font-weight: 300;
                font-style: normal;
            }
            
            .hide {
                display: none;
            }
            
            .show {
                display: block;
            }

        }
        p {
           
        }
    }

    .needs {
        margin: 40px 0;


        ul {
            font-family: $font1;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;
            width: 80%;

            li {
                text-align: left;  
            }
        }
    }

      h3 {
         grid-column: 1/3;
      }

      .carousel {
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        grid-column: 1/3;
        padding: 40px 0;
        gap: 40px;

        .no_event {
            p {
                display: flex;
                align-items: center;
                gap: 20px;
                color: #636363;
                margin: 0 auto;
            
                svg {
                    font-size: 30px;
                    color: #636363;
                }
            }
        }

        .months {

            .month {
                text-transform: capitalize;
        }
        
        
        .events {
            margin: 50px 0;
            display: flex;
            gap: 35px;
            flex-direction: column;
            
            .event {
                background: $white;
                padding: 20px;
                border-radius: 25px;
                width: 250px;
                margin: 0 auto;
                transition: 0.3s ease-in;
                cursor: pointer;
                box-shadow: 0px 25px 0px -6px #e63b104f;
                
                &:hover {
                    transform: translateY(25px);
                }
                
                
                h4 {
                    font-family: $font2;
                }
                
                p {
                    font-family: $font1;
                    text-align: center;
                    margin: 20px 0;
                }
            }
          }

          .outDated {
            filter: grayscale(1) brightness(0.9);
          }
        }
      }
   }

   .ideas {

    .grids {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 150px;
        margin-bottom: 150px;
        margin-left: -145px;
        align-items: center;

      .bottom {
        grid-template-columns: 50% 50%;
        width: 80%;

        .row {
            grid-template-columns: 54% 46%;

            &:first-child {

                .img {

                    img {
                    max-width: 230px;
                }
              }
            }

        }
      }

      .top {
        grid-template-columns: 33% 33% 33%;

        .row {
            grid-template-columns: 60% 33%;

            &:last-child {

                .img {

                    img {
                    max-width: 115px;
                }
              }
            }
        }
      }

      .top, .bottom {
        display: grid;
        gap: 60px;

          
          .row {
            display: grid;
            align-items: center;
            width: 100%;
            gap: 30px;
            border-radius: 25px 0 25px 0;
            padding: 20px;
            border: 3px solid #edb8ab;
            background: #ea846a09;
            transition: 0.3s ease-in;
            backdrop-filter: blur(2px);

            &:hover {
                border-radius: 0 25px 0 25px;
            }

                .emplitudeLogo {
                    max-width: 100px;
                }

                > div {
                    display: flex;
                    flex-direction: column;

                    p {
                        &:nth-child(1) {
                            font-weight: 600;
                        }

                        &:nth-child(2) {
                            font-size: 14px;
                        }
                    }

                    a {
                        font-size: 14px;
                        color: #ea836a;
                    }
                }

                p {
                    text-align: right;
                }

                .mail, .tel {
                    text-align: right;
                }
            
                .img {
                   display: flex;
                   align-items: center;

                    img {
                        margin: 0 auto;
                        display: block;
                        max-width: 150px;
                        padding: 0 20px;
                    }

                    &::before {
                        content: " ";
                        background: #edb8ab;
                        display: block;
                        height: 50px;
                        min-width: 3px;
                    }
                }

                .imgs {

                    .img {
                

                        &:nth-child(1) {
                            
                            img {
                                max-width: 250px;
                            }
                        }
                        &:nth-child(2) {
                            
                            img {
                                max-width: 150px;
                            }
                        }
                    }
                    
                }
          }
        }

        

                h3 {
                    grid-column: 1/3;
                }

                .col {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 40px;

                    img {
                        cursor: pointer;
                    }

                    &:nth-child(3) {
                        img {
                            width: 150px;
                        }
                    }
                }
                
            

    }

    .bilanCarbone {
        width: 95%;
        margin: 80px auto;


        strong {
            font-family: $font3;
        }
        

        .bloc {
            background: $green;
            margin: 145px -290px;
            height: 53vh;
            position: relative;

            .imgBox {
                clip-path: polygon(29% 0%, 100% 0%, 75% 100%, 0% 100%);
                width: 895px;
                margin: 0 25px;

                img {
                    width: 965px;
                    filter: saturate(1.5);
                }
            }
            

            .right {
                position: absolute;
                top: -1px;
                left: 44%;
                height: 45vh;
                display: flex;
                align-items: center;

                .animatedText {
                    margin: 80px 245px 0;

                    p {
                       
                        margin: 0 auto;
                        font-weight: 600;
                        text-align: left;
                        font-size: 30px !important;
                        color: $white;
                        font-family: $font3;
                        line-height: 1;
                    }
                }
            }
        }

        .blocs {
            display: grid;
            grid-template-columns: 33% 33% 33%;
            gap: 20px 30px;
            margin: 150px auto 80px;

            .slideBloc {
                display: grid;
                grid-template-columns: 25% 75%;
                align-items: center;
                gap: 8px;
                transform: translateY(50px);
                opacity: 0;

                p {
                    text-align: left;
                }

                svg {
                    margin: 0 auto;
                    color: $green;
                }
            }

            .slideIn {
                animation: slideIn 0.5s linear;
                animation-fill-mode: forwards;
            }
            
        }
        
        .suggestions {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;

            a {
            border: 1px solid $green;
            color: $green;
            padding: 5px 10px;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            width: fit-content;
            transition: 0.3s ease-in;


            &:hover {
                background: $green;
                color: $white;
            }
        }
      }
    }
    
   
   }

   .expertsBFC {
     margin-bottom: 150px;

       .grid_4 {
        display: flex;
        flex-direction: column;
        margin: 80px auto;
        width: 100%;
        gap: 20px;
        justify-items: start;


        .bckg_white {
            background: #fff;
        }

        > div {
            padding: 20px 0;
            display: grid;
            grid-template-columns: 19% 77%;
            gap: 20px;
            align-content: center;
            align-items: center;
            justify-items: start;
            height: 200px;

            &:hover {
                img {
                    border-radius: 35px;
                }
            }
        }

        .text {
            opacity: 0;
            transition: opacity 1s ease-in;
        }

        .text.visible {
            animation: fadeIn 1s linear;
            animation-fill-mode: forwards;
        }

        .fade-in {
            opacity: 0;
            transition: opacity 1s ease-in;
        }
          
        .fade-in.visible {
            animation: fadeIn 0.5s linear;
            animation-fill-mode: forwards;
        }

        .imgBox {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 10px;
           

            a {
                display: flex;
                justify-content: center;
    
                img {
                    max-width: 130px;
                    max-height: 200px;
                    transition: 0.3s ease-in;

                  
                }
            }


        }

       

        h4 {
            text-align: left;
        }

        
       }
   }

   .normesEtLabelRSE {


    .normes {
        .grid_5 {
            display: grid;
            grid-template-columns: 50% 50%;
            gap: 20px;
            width: 75%;
            align-items: center;
            align-content: center;
            justify-content: center;
            justify-items: center;
            margin: 0 auto;

            ul {
                display: flex;
                flex-direction: column;
                gap: 15px;

                li {
                    list-style-type: none;
                    text-align: left;
                    cursor: pointer;

                    &:hover {
                        a {
                            color: $orange;
                        }
                    }

                    a {
                        color: #222;
                        transition: 0.3s ease-in;
                        display: grid;
                        grid-template-columns: 10% 90%;
                        align-items: center;

                        svg {
                            color: #ea836a;
                        }


                        .text {
                            display: flex;
                            flex-direction: column;


                            p {
                                font-weight: 400;
                                font-style: italic;
                            }
                        }
                    }
                }
            }


            img {
                width: 395px;
                border-radius: 25px;
                box-shadow: 0px 0px 29px -14px #222;
            }
        }
    }


    .labels {
        margin-top: 100px;


        .labels_text {
            width: 70%;
            margin: 50px auto 150px;
        }
   
    
        .grid_6 {
            display: grid;
            grid-template-columns: 25% 25% 25% 25%;
            gap: 25px;
            width: 90%;
            margin: 0 auto;

            .labels_col {
             display: flex;
             flex-direction: column;
             gap: 15px;

             h4 {
                font-weight: 500;
                font-size: 20px;
                font-family: $font1;
             }

             p {
                text-align: center;
             }

            .label {
                background: #fff;
                border-radius: 25px;
                padding: 20px;
                transition: 0.3s ease-in;

                &:hover {
                    background: $light_orange;


                    .box {
                        
                        p, a {
                            color: #cd6f5a;
                        }
                    }
                }


                .emp {
                    width: 80%;

                    p {

                        &:nth-child(2) {
                            color: $black;
                            font-weight: 400;
                        }
                    }
                }

                .box {
                    display: flex;
                    flex-direction: column-reverse;
                    align-items: center;
                    justify-items: center;
                    gap: 10px;

                    p {
                        color: $orange;
                        text-align: center;
                        transition: 0.3s ease-in;
                    }
                    
                     img {
                        max-height: 115px;
                        max-width: 100px;
                        width: auto;
                     }
                    
                }
            }
         }
      }
    }
   }

   .events  {
    margin: 150px 0;

    .grid_7 {
        display: flex;
        flex-direction: column;
        gap: 15px;
        max-width: 90%;
        width: auto;
        margin: 0 auto;

        .row {
            display: grid;
            align-items: center;
            justify-items: center;
            gap: 40px;

            &:nth-child(1), &:nth-child(3) {
             grid-template-columns: 40% 60%;
            }

            &:nth-child(2) {
                grid-template-columns: 60% 40%;
            }

            img {
                max-width: 400px;
                min-width: 200px;
                width: auto;
                border-radius: 25px;
                cursor: pointer;
            }
        }
    }
    

}


    .isBouncing {
        animation: bounce 0.4s ease-in-out 3;


    }


    .btn {
        width: 235px;
        padding: 15px;
        font-family: $font1;
        color: $white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s ease-in;
        text-align: center;
        display: flex;
        gap: 10px;
        

        a {
            color: $white;
            text-align: center;
        }

    }
   
   .contact {
    display: block;
    max-width: 75%;
    margin: 150px auto;
    padding: 145px;

    p {
        text-align: center;
    }


        h3 {
            grid-column: 1/4;
        }

        .card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            grid-template-rows: 36% 17% 33%;
            background-image: url("https://action-rse.fr/Assets/gradient.png");
            background-size: cover;
            backdrop-filter: blur(5px);
            border-radius: 10px;
            // border-right: 2px solid #ea836a;
            // border-left: 2px solid #dd60ad;
            // border-bottom: 2px solid #f995d3;
            // border-top: 2px solid #ffdcf2;
            padding: 20px;
            width: 500px;
            height: 300px;
            gap: 20px;
            align-items: center;
            margin: 50px auto;
            transition-duration: 0.1s;
            perspective: 500px;
            cursor: pointer;


            &::before {
                content: '';
                height: 100%;
                width: 100%;
                background-image: url('http://alexandrebuffet.fr/codepen/parallax-cards/blur-overlay.jpg');
                background-size: cover;
                background-position: center center;
                border-radius: 10px;
                margin-top: -20px;
                opacity: 0.5;
                position: absolute;
                mix-blend-mode: lighten;
            }

            > :nth-child(n+1) {
                position: relative;
                z-index: 6;
            }

            .logo {
            

                img {
                    max-width: 190px;
                    opacity: 0.7;
                    margin-top: 75px;
                    filter: grayscale(1) brightness(5);
                }
            }

            .info {
                display: flex;
                flex-direction: column;

                .row {
                    display: grid;
                    grid-template-columns: 7% 93%;
                    align-items: center;


                    svg {
                        color: $white;
                    }
                    
                    a {
                        
                        color: $white;
                        font-size: 20px;
                        font-family: $font3;
                    }
                }
            }

            // &:hover {
            //     border-top: 2px solid #ea836a;
            //     border-bottom: 2px solid #dd60ad;
            //     border-left: 2px solid #f995d3;
            //     border-right: 2px solid #ffdcf2;
               

            // }
        }

        .is-out {
            transform: rotateX(0) rotateY(0) !important;
            transition-duration: 1s;
        }
      }
    }
  
@media screen and (min-width: 1651px) {
  
    .homePage {
        padding: 0 255px;
    }

    
}

  @media screen and (min-width: 1201px) and (max-width: 1650px) {
    .homePage {
        padding: 0 150px;

        .logos {
            .imgBox {

                
                &:nth-child(4)
                
                {
                    img {
                        width: 220px;
                    }
                }

                &:nth-child(-n + 3)
                
                {
                    img {
                        width: 95px !important;
                    }
                }
            }
        }

        .team {
            display: grid;
            grid-template-columns: 50% 50%;
            margin: 105px auto;
            gap: 25px;
            width: 85%;
            }
        
        .definition {

            
            .bloc {
                margin: 145px -150px;
                
                .imgBox {
                    margin: 0 20px;
                    width: 660px;

                    img {
                        height: 400px;
                    }
                }
                
                .right {
                    
                    .whiteFrame {
                        
                        .author {
                            left: 64%;
                            top: 82%;
                        }
                        
                        img {
                            max-width: 118px;
                        }
                    }
                }
            }
        }

        .paraph_2 {
            .pillars {

                > div {
                    img {
                        width: 65px;
                    }
                }
            }

            .afnor_img {
                width: 500px;
            }


            .grid {

                img {
                    max-width: 320px;
                }
            }
        }
        .ideas {


            .bilanCarbone {

                .bloc {
                    margin: 145px -176px;

                    .right {

                        
                        .animatedText {
                            margin: 85px 108px 0;
                        }
                        
                    }

                    .imgBox {
                        width: 740px;
                        height: 415px;
                        margin: 0 15px;
                    }

                }
                
              }
            .grids {
                margin-left: -40px;
                .top, .bottom {
                    gap: 20px;

                    .row {
                        width: 90%;

                        .img {
                            img {
                                max-width: 85px;
                                max-height: 75px;
                                padding: 0;
                            }
                        }
                    }
                }

                .top {
                    .row {
                        grid-template-columns: 69% 26%;
                        gap: 20px;
                    }
                }
            }
        }

        .normesEtLabelRSE {

            .normes {

                .grid_5 {
                    img {
                        width: 300px;
                    }
                }
            }

            .labels {
                .grid_6 {
                    width: 97%;

                    .labels_col {
                        .label {
                            min-height: 180px;

                            .box {
                                justify-content: center;
                                height: 100%;

                                img {
                                    max-width: 95px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .events {

            .grid_7 {
                width: 95%;
            }
        }

        .contact {
            width: 90%;
        }

    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1200px) {
  
    .homePage {
        padding: 0;

        .whatIsIt {
            margin: 150px 60px 0;
            padding: 60px 0;
            
            img {
                // max-width: 35%;
            }
        }

        .paraph_1 {

            ul, a {
                width: 80%;
            }

           
        }

        .paraph_2 {

            .grid {
                grid-template-columns: 50% 28%;
                

                p {
                    margin: 0;
                }

                img {
                    max-width: 300px;
                }

              
            }


            .pillars {
                gap: 15px;
            }
        }

        .paraph_3 {


            p {
                margin: 0;
            }
        }

        .grid_2 {
            max-width: 85%;
            width: auto;



            > div {
                min-width: 250px;
            }
        }

        .ideas {



            .bilanCarbone {
                max-width: 65%;
                width: auto;
            }

            .grids {
                display: flex;
                flex-direction: column;

            }
        }

        .normesEtLabelRSE {


            .normes {

                .grid_5 {
                    max-width: 80%;
                    width: auto;
                }
            }

            .labels {

                .grid_6 {

                    .labels_col {
                     
                        .label {

                            .emp {
                                width: 80%;
                            }

                            .box {

                                img {
                                    max-height: 80px;
                                    max-width: 85px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .events {

            .grid_7 {
                max-width: 80%;
                width: auto;

                .row {

                    &:nth-child(1), &:nth-child(2), &:nth-child(3) {

                        grid-template-columns: 50% 50%;
                        gap: 45px;
                    }
                }
            }
        }
        .contact {
            width: 95%;
        }
    }
  }

  @media screen and (min-width: 801px) and (max-width: 1024px) {
    .homePage {
        padding: 0 50px;

        

        .whiteFrame {
            width: 90%;
            
                &:nth-child(1) {

                    img {
                        left: 37% !important;
                        top: 82% !important;
                    }
                }

              
            
        }


        .whatIsIt {

            .whiteFrame {

                .author {
                    left: 25%;
                    top: 82%;
                }
            }

            .team {
                width: 100%;
            }

          

            .logos {
                max-width: 100%;
            }
        }

        .paraph_1 {
            margin: 100px 50px;;
        }

        .paraph_2 {


            

            .pillars {
                display: flex;
                flex-direction: column;
            }

            .grid {
                display: flex;
                flex-direction: column;
                gap: 75px;


                p {
                    margin: 0;
                }
            }

            h3 {
                margin: 95px auto;
                width: 95%;
            }
        }

        .paraph_3 {
            ul {
                margin-left: 0 !important;


                li {
                    font-size: 18px !important;

                    svg {
                        font-size: 20px !important;
                    }
                }
            }
        }

        .grid_2 {
            display: flex;
            flex-direction: column;
            gap: 20px;

            > div  {
                margin: 0 !important;
            }
        }

        .ideas {
            .grids {
                display: flex;
                flex-direction: column;
                margin-left: 0;

                .top, .bottom {
                    display: flex;
                    gap: 20px;
                    flex-direction: column;
                    margin: 0 auto;
                    width: 70%;

                    .row {
                        max-width: 100%;
                    }
                }
            }

            .bilanCarbone {
                width: 80%;
                
            }
        }

        .expertsBFC {

            .grid_4 {
                width: 95%;

                .row {
                    padding: 20px 55px;
                    gap: 45px;
                }
            }
        }

        .normesEtLabelRSE {

            .normes {
                .grid_5 {
                    display: flex;
                    flex-direction: column;
                    width: 90%;
                }
            }

            .labels {
                
                .grid_6 {
                    grid-template-columns: 50% 50%;



                    .label {
                        min-height: 150px;

                        .box {
                            height: 100%;
                            width: 100%;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }

        .events {

            .grid_7 {
                .row {
                    &:nth-child(1) {
                        display: flex;
                        flex-direction: column;


                        .imgs {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        }
                    }
                }
            }
        }

        .contact {
            padding: 90px;
            margin: 0 auto;
        }

    }
    
  }

  @media screen and (min-width: 300px) and (max-width: 800px) {

    .homePage {
        padding: 20px;

        a {
            line-break: anywhere;
        }
        

        p {
            margin: 20px auto !important;
            width: 90% !important;
            text-align: left;

        }

        .btn {
            width: 270px;
        }

        .logos {
            max-width: 100%;
            gap: 50px 40px;

            .imgBox {
                &:nth-child(1),  &:nth-child(2),  &:nth-child(3) {
                    height: 100%;

                    img {
                        width: 95px !important;
                    }
                }


                &:nth-child(4) {

                    img {
                        width: 175px;
                    }
                }
            }
        }
      

        .whatIsIt {
            margin: 20px 0 0;
            padding: 135px 0 0 0;

            

            ul {
                margin-left: 50px !important;
            }

        }

        .team {
            width: 80%;
            display: flex;
            flex-direction: column;
            margin: 0 auto;

            .person {
                

                .info {
                    min-width: 240px;
                }


                svg {
                    font-size: 70px;
                }
            }
        }

        .definition {

            .bloc {
              margin: 145px -20px;
              height: 80vh;

                .imgBox {
                    display: none;
                }

                .right {
                    left: 0;
                    position: relative;
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    height: 93%;

                    .mobileDisplay {

                        strong {
                            color: $white;
                            font-family: $font1;
                        }

                        p {
                            color: $white;
                        }
                            

                        img {
                            width: 40%;
                            border-radius: 15px;
                            margin: 0 auto;
                            display: block;
                        }
                    }
                }
            }

        }

        .caution {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .text {
                p {
                    width: 100%;
                    margin: 20px 0 !important;;
                }
            }
        }

        .paraph_1 {
            margin: 20px 0;
            width: 90%;

            ul, a {
                width: 90%;
            }
        }

        .paraph_2 {
            margin: 150px 0 20px;

            h3 {
                width: 77%;
            }

            .pillars {
                display: flex;
                flex-direction: column;
            } 
            
           

            a {
                img {
                    max-width: 380px;
                    min-width: 200px;
                    width: auto;
                }
            }

            h3 {
                margin: 20px auto;
            }
        }

        .caution {
            margin: 120px auto;
        }

        .grid {
            display: flex;
            flex-direction: column;
            gap: 45px;

            img {
                max-width: 350px;
            }
        }

        .paraph_3 {

            ul {
                margin-left: 13px !important;

                li {
                    font-size: 15px !important;
                    grid-template-columns: 7% 88%;
                    gap: 8px
                }
            }
        }

        .grid_2 {
            display: flex;
            flex-direction: column;
            width: 100%;

            .carousel {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }

            .practices, .needs  {
                margin: 10px 0;
                max-width: 89%;
                min-width: 50%;
                width: auto;
            }


            a {
                width: 100%;
                display: block;
            }
        }

        .events {
            margin: 0;

            .btn {
                margin: 50px auto;
            }
        }

        #tester_mes_connaissances {
            button {
                margin: 45px auto 50px;
            }
        }

        .expertsBFC {
            margin-bottom: 0;
            
            .grid_4 {
                display: flex;
                flex-direction: column;
                width: 100%;

                > div {
                    height: auto;
                    padding: 35px 0;
                    display: flex;
                    flex-direction: column;
                }

                .imgBox {


                  a {

                      img {
                          max-width: 100px !important;
                        }
                    }
                }
            }
        }

        .ideas {


            .bilanCarbone {

                .bloc {
                    margin: 145px -28px;


                    .right {
                        left: 0;
                        position: relative;
                        padding: 20px;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        height: 93%;


                        .animatedText {
                            margin: 0 auto;
                            
                            p {
                                font-size: 20px !important;
                                width: 100% !important;
                            }
                        }
                    }

                    .imgBox {
                        display: none;
                    }
                }

                .blocs {
                    gap: 20px 8px;
                    grid-template-columns: 50% 50%;


                    .slideBloc {
                        display: flex;
                        flex-direction: column;

                        p {
                            text-align: center;
                        }


                        &:last-child {
                            grid-column: 1/3;
                        }
                    }
                }

                .suggestions {

                    a {
                        padding: 10px 18px;
                    }
                }
            }

            .grids {
                display: flex;
                flex-direction: column;
                margin-left: 0;

                .top, .bottom {
                    display: flex;
                    gap: 20px;
                    flex-direction: column;
                    margin: 0 auto;
                    width: 100%;
                    align-items: center;

                    .row {
                        max-width: 87%;
                        display: flex;
                        flex-direction: column-reverse;

                        img {
                            max-width: 80px;
                        }
                    }
                }

                
                     img {
                        max-width: 80px;
                     }   

                

                .left {

                    .row {
                        grid-template-columns: 54% 44%;
                        
                         img {
                            max-width: 120px;
                        }

                        .imgs {
                            .img {
                                justify-content: center;
        
                                &:nth-child(1) {
        
                                    img {
                                        max-width: 150px;
                                    }
                                }

                                &:nth-child(2) {
        
                                    img {
                                        max-width: 85px;
                                    }
                                }
                            }
                        }
                    }
                   
                }

                .right {
                    .row {
                        grid-template-columns: 54% 44%;

                        img {
                            max-width: 100px;
                        }
                    }
                }
            }
        }

        .normesEtLabelRSE {

            
            .normes {
                .grid_5 {
                    display: flex;
                    flex-direction: column;
                    width: 90%;

                    img {
                        width: auto;
                        max-width: 310px;
                    }

                }
            }

            .labels {

                .grid_6 {
                    display: flex;
                    flex-direction: column;

                    .labels_col {
                        .label {
                            .box {
                                img {
                                    min-width: 75px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .events {

            .grid_7 {

                .row:nth-child(1) {
                    display: flex;
                    flex-direction: column;
                    
                    .imgs {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        gap: 20px;

                        img {
                            max-width: 300px !important;
                        }
                    }
                }
            }
        }

        .calendar {
            margin: 0 !important;
        }

        .contact {
            padding: 5px;
            max-width: 90%;
            margin: 0 auto;
           
            .card {
                width: 300px;
                height: 170px;


                .info {
                    width: 95%;


                    .row {
   
                        a {
                            font-size: 16px;
                        }
                    }
                }


                .logo {

                    img {
                        max-width: 130px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }

}