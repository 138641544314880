.addEvents {
    margin: 150px 0;
    padding: 80px 150px;


   
}


.eventForm {
    display: flex;
    flex-direction: column;
    margin: 40px auto;
    width: 40%;
    gap: 20px;
    font-family: $font1;

    .validationText {
        text-align: center !important;
        color: rgb(255, 101, 101);
        font-weight: 600;
    }

    input {
        height: 35px;
        border: none;
        border-radius: 25px;
        padding: 0 10px;
        color: rgb(69, 90, 255);
        font-weight: 700;
        width: 100%;
       
        &::placeholder {
            font-family: $font1;
            font-weight: 400;
        }
    }


    .pickers {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 40px;

        > div {
            display: grid;
            grid-template-columns: 30% 70%;
            align-items: center;
            gap: 10px;
        }
    }

    button {
        padding: 15px 20px;
        background: rgb(69, 90, 255);
        color: $white;
        font-weight: 600;
        font-family: $font1;
        border: none;
        border-radius: 25px;
        transition: 0.5s ease-in;
        width: 45%;
        margin: 0 auto;
        cursor: pointer;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;


        &:hover {
            background: $white;
            box-shadow: 0px 0px 50px 0px rgb(69, 90, 255);
            color: rgb(69, 90, 255);
        }
    }

    .react-datepicker__navigation {
        align-items: center;
        background: none !important;
        display: flex;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        position: absolute;
        top: 2px;
        padding: 0 !important;
        border: none;
        z-index: 1;
        height: 32px;
        width: 32px !important;
        text-indent: -999em;
        overflow: hidden;
    }

    textarea {
        font-family: $font1;
        border: none;
        border-radius: 25px;
        padding: 5px 20px;
        color: rgb(69, 90, 255);
        font-weight: 600;

        &::placeholder {
            font-family: $font1;
        }
    }
}