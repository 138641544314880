.archiveList {
    margin: 150px 0;

   
 
    .list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 80%;
        margin: 50px auto;
        height: 70vh;
        overflow-y: scroll;
        padding: 20px;
        

         
        &::-webkit-scrollbar {
            width: 5px;
            height: 10px;
            margin-left: 25px;
          
        } 
        
        &::-webkit-scrollbar-thumb {
            background: $orange;
            border-radius: 25px;
         }
      

        .archive {
            background: $white;
            padding: 40px;
            border-radius: 15px;

            .categories {
                margin: 20px 0;
                display: flex;
                gap: 10px;
                justify-content: flex-start;

                .category {
                border: 2px solid #444444;
                padding: 5px 10px;
                border-radius: 25px;
                font-family: "Inter";
                font-size: 15px;

                }
            }
            
            .animateurs {
                li {
                    list-style: none;
                }
            }

            button {
                display: flex;
                align-items: center;
                margin: 20px auto;
            }
        }
    }

}