
.navigation {
    display: block;
    padding: 20px;
    position: fixed;
    width: calc(100% - 20px);
    margin-top: -150px;
    gap: 10px;
    justify-items: center;
    z-index: 1000;
    opacity: 0;
    filter: blur(25px);
  

    .nav {
      display: flex;
      gap: 35px;
      height: 45px;
      padding: 0 230px;
      // background-image: url("https://firebasestorage.googleapis.com/v0/b/action-rse-c3198.appspot.com/o/nav_bkgd.png?alt=media&token=2ca5217d-9cc2-4b11-89ce-6118dd437bda");
      background-image: url("https://action-rse.fr/Assets/gradient.png");
      clip-path: polygon(13% -10%, 77% -261%, 89% 49%, 86% 143%, 15% 164%, 11% 49%);
      justify-content: center;
      width: 80%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: 0.3s ease-in;
      position: relative;

      .picto {
        position: absolute;
        width: 70%;
        height: 100%;
        opacity: 0.75;
        object-fit: cover;
      }

  
      li {
        list-style-type: none;
        font-family: "Inter";
        font-weight: 600;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 40px;
        position: relative;
        z-index: 10;

        
      // &:nth-child(2) {
      //   width: 240px
      // }

        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          
          a {
            &:hover {
              color: #ffaa95;
            }
          }

          .subMenu {
            
  
            li {
             
              a {

                &:hover {
                  color: #e63b10;
                 }  
               }
            }
         } 
        }

        &:nth-child(4), &:nth-child(5), &:nth-child(6) , &:nth-child(7) {

          a {

            &:hover {
              color: #a81580;
            }
          }

        }
      
       
        a {
          font-size: 16.2px;
          color: #fff;
        }

        

        .subMenu {
          display: none;
          background-color: #ffffff67;
          background-image: none;
          border-radius: 15px;
          padding: 10px;
          margin-left: 0;
          margin-bottom: 10px;
          flex-direction: column;
          box-sizing: border-box;
          width: 100%;
          gap: 0;

          li {
            transition: 0.3s ease-in;
            height: 30px;
            display: flex;
            align-items: center;
            margin-top: 0;

            
          }
        }
  
        a {
          text-decoration: none;
          color: $white;
          transition: 0.3s ease-in;
        }


      }
    }
  }

  @media screen and (min-width: 1201px) and (max-width: 1600px) {
    .navigation {

      
      .nav {
        li  {
          
          a {
          font-size: 13.2px;
          }

          .subMenu {
            li {

              a {
                font-size: 13.2px
              }
            }
          }
     }
    }
   }
  }

  @media screen and (min-width: 1025px) and (max-width: 1200px) {
    .navigation {
      display: grid;
      grid-template-columns: 12% 88%;

      ul {
        gap: 15px;

        li {

          a {
            font-size: 12.2px;
          }


          .subMenu {

            li {

              a {

                font-size: 12.2px;
              
              }
            }
          }
        }
      }
    
      img {
        max-width: 140px;
      }
    
    }
  
  }

  @media screen and (min-width: 801px) and (max-width: 1024px) {
    .navigation {
      display: grid;
      grid-template-columns: 21% 77%;
      justify-items: end;
      justify-content: space-between;
      width: 97vw;

      #burgerNav {
        width: auto;
        height: auto;
        padding: 0 40px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        z-index: 6;
 


        .bar {
          width: 40px;
          height: 4px;
          background: $black;
          transition: 0.3s ease-in;

         
        }
      }

      .activeBurgerNav {

        .bar {
          &:nth-child(1) {
              transform: rotateZ(45deg) translateX(2px);
              transform-origin: top left;
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
              transform: rotateZ(-45deg) translateX(-2px);
              transform-origin: top left;
          }

        }
      }

        #nav {
          display: flex;
          flex-direction: column;
          position: absolute;
          width: 100%;
          padding: 80px 200px;
          background: #e7e7e7ef;
          backdrop-filter: blur(2px);
          transition: 0.3s ease-in;
        }

        .inactiveNav {
          left: 100%;
        }

        .activeNav {
          left: 0;
        }
    }
    
  }

  @media screen and (min-width: 300px) and (max-width: 800px)  {

    .navigation {
      background-image: url(https://action-rse.fr/Assets/gradient.png);
      display: block;
      justify-content: space-between;
      margin-top: -20px;
      width: 95vw;
      background-size: cover;


      #burgerNav {
        width: auto;
        height: fit-content;
        padding: 0 20px;
        margin: 20px 10px;
        position: relative;
        float: right;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        z-index: 6;


        .bar {
          width: 40px;
          height: 4px;
          background: $white;
          transition: 0.3s ease-in;
          list-style-type: none;

         
        }
      }

      .activeBurgerNav {

        .bar {
          &:nth-child(1) {
              transform: rotateZ(45deg) translateX(2px);
              transform-origin: top left;
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
              transform: rotateZ(-45deg) translateX(-2px);
              transform-origin: top left;
          }

        }
      }

        #nav {
          display: flex;
          flex-direction: column;
          gap: 40px;
          position: absolute;
          width: 78vw;
          height: 68vh;
          padding: 130px 50px;
          backdrop-filter: blur(2px);
          transition: 0.3s ease-in;

          li {
            list-style-type: none;


            a {
              font-size: 20px;
              color: $white;
            }
          }
        }

        .inactiveNav {
          left: 100%;
        }
    }

  }