@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Montserrat:ital,wght@0,300..900;1,300..900&display=swap');


$font1: "Inter";
$font2: "Cardo";
$font3: "Montserrat";

$bkgd: #f1f1f1;
$black: #444444;
$grey: #888888;
$light_grey: #dddddd;
$orange: #f29b88 ;
$light_orange: #ffd8a8;
$magenta_pastel:#e68cb5;
$pastel_pink: #f7bede;
$green: #009d91;
$green_2: #8ddad1;
$white: #fff;

* {
    padding: 0;
    margin: 0;
}

html {
    font-size: 16px;
}

body {
    background-color: $bkgd; 
    background-image: url('https://action-rse.fr/Assets/bkgd.png');
    background-attachment: fixed;
    background-blend-mode: multiply;
    background-size: 89%;
    // cursor: none; 
}


.filigrane {
    width: 100vw;
    position: absolute;
    opacity: 0.2;
}

.back {
    width: 150px;
    padding: 15px;
    font-family: $font1;
    color: $white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease-in;
    text-align: center;
    display: flex;
    gap: 10px;
    justify-content: center;

    a {
        color: $white;
    }


    &:hover {
        background: #636363;
    }
 }


  .custom-cursor {
    position: absolute;
    width: 40px; 
    height: 40px;
    border-radius: 50%; 
    border: 1px solid #009355; 
    pointer-events: none; 
    transform: translate(-50%, -50%);
    z-index: 9999;
  }

h1, h2, h3, h4 {
    margin: 50px auto;
    font-family: $font2;
    text-align: center;
}

h4, h5 {
    color: $black;
}

h1 {
    font-size: 40px;
    color: $orange;
}

h2 {
    font-size: 42px;
    font-weight: 900;
    background: -webkit-linear-gradient($light_orange, $magenta_pastel);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}




h3 {
    font-size: 30px;
    background: -webkit-linear-gradient($grey, $black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

h4 {
    font-size: 25px;
}


h5 {
    font-family: $font3;
    font-style: italic;
    font-size: 16.8px;
    font-weight: 400;
}

a, a:visited {
    text-decoration: none;
    color: $orange;
    font-weight: 600;
    font-family: $font1;
    line-height: 1.55;
    font-size: 16.8px;
    text-align: justify;
    text-justify: inter-word;
}

p, li {
    color: $black;
    font-family: $font1;
    line-height: 1.55;
    font-size: 16.8px;
    text-align: justify;
    text-justify: inter-word;
}


strong {
    color: $black;
}

.anchor {
    margin-top: -150px;
    position: absolute;
}

small {
    font-family: $font1;
    font-size: 13px;
    color: rgb(226, 15, 15);
}


button {
    background: $orange;
    transition: 0.3s ease-in;
    width: 235px;
    padding: 15px;
    font-family: $font1;
    color: $white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease-in;
    text-align: center;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;


    a, a:visited {
        width: 100%;
        color: $white;
        text-align: center;
    }

    &:hover {
        background: $magenta_pastel;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
  
    h1 {
        font-size: 25px;
    }
    
    h2 {
        font-size: 32px;
        font-weight: 900;
    }
    
    h3 {
        font-size: 20px;
    }
    
    h4 {
        font-size: 16.8px;
    }

    h5 {
        font-family: $font1;
        font-style: italic;
        font-size: 12.8px;
        font-weight: 400;
    }

    a, a:visited {
        font-size: 13.8px;
    }

    p, li, strong, span {
        font-size: 13.8px !important;
    }
    
  }

  @media screen and (min-width: 801px) and (max-width: 1024px) { 

    h2 {
        font-size: 22px;
    }
  }

  @media screen and (min-width: 300px) and (max-width: 800px) {
    h1 {
      font-size: 24px;   
        
    } 

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 18px;
    }

    p {
        font-size: 16px;
    }

    .back {
        margin: 20px auto;
    }
  }