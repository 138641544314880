.calendar {
    padding: 20px;
    background: $white;
    border-radius: 25px;
    
    .rbc-calendar {
        font-family: $font1;
        
        span {
            font-family: $font1;
        }

        .rbc-event {
            background: #f29b88;
        }

        .rbc-today {
            background: #f29b8852;
        }


    }
    
    

}

.rbc-button-link {
    width: 103% !important;
}

@media screen and (min-width: 300px) and (max-width: 800px) {

    .rbc-btn-group {
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        width: 100%;
    }

    .rbc-toolbar button {
        display: block !important;
        width: 100%;
    }

}