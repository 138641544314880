.mentions_legales {
    padding: 0 150px;
    
    .content {
        margin: 150px 90px 0;
        
        p {
            padding: 50px;
        }
    }
}